import { HttpResponse } from "@capacitor/core";
import { CheckIcon } from "@heroicons/react/20/solid";
import { IonText } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Theme from "../data/Theme";
import { GET, JIBI_BASE_URL, PATCH } from "../util/ApiClient";
import { UserContext } from "../util/BetterDatesApp";
import { handleError } from "../util/error";
import { ModalStateType } from "./modal/Modal";

interface ItemProps {
  text: string;
  bgColor?: string;
  onClick?: () => void;
  color?: string;
}

const Item = ({
  text,
  bgColor = "bg-gray-200",
  onClick,
  color,
  isSelected,
}: ItemProps & { isSelected: boolean }) => {
  return (
    <button
      className={`${bgColor} flex h-15 w-full items-center justify-between rounded-lg p-3 text-left`}
      style={{
        background: bgColor,
        color: color,
      }}
      onClick={onClick}
    >
      {text}
      {isSelected && <CheckIcon className="h-5 w-5" />}
    </button>
  );
};

interface ThemeColorItemsListProps {
  setModalState: React.Dispatch<React.SetStateAction<ModalStateType>>;
}

export default function ThemeColorItemsList({
  setModalState,
}: ThemeColorItemsListProps) {
  const userContext = useContext(UserContext);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [loadingTheme, setLoadingTheme] = useState(true);

  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }

  const savedUser = userContext.userState.savedUser;

  useEffect(() => {
    setLoadingTheme(true);
    fetchThemes(savedUser.token)
      .then((response) => {
        if (response.status === 200) {
          const themeResponse = response.data.items as Theme[];
          setThemes(themeResponse);
        } else {
          return Promise.reject();
        }
      })
      .catch((e) => {
        // TODO: use an empty state instead because it's for the whole page.
        handleError(e, userContext);
      })
      .finally(() => {
        setLoadingTheme(false);
      });
  }, []);

  const handleClickUpdateUser = async (selectedTheme: Theme) => {
    try {
      const response = await updateTheme(selectedTheme, savedUser.token);
      if (response.status === 200) {
        const updatedUser = {
          ...savedUser.user,
          theme: selectedTheme,
        };
        const updatedSavedUser = {
          ...savedUser,
          user: updatedUser,
        };
        userContext?.loginHook.saveUser(updatedSavedUser);
        setModalState({ isVisible: false });
        return updatedSavedUser;
      } else {
        return Promise.reject(response.status);
      }
    } catch (error) {
      console.log("Failed to update user data", error);
    }
  };

  return (
    <div className="safe-scroller mx-2 mb-4 mt-4 flex touch-auto flex-col justify-items-center gap-2 overflow-y-scroll no-scrollbar">
      {loadingTheme ? (
        <IonText className="flex h-screen items-center justify-center">
          Loading...
        </IonText>
      ) : (
        <>
          {themes.length > 0 ? (
            themes.map((theme, index) => (
              <div key={index} className="space-y-4">
                <Item
                  color={theme.t0000}
                  text={theme.themeName}
                  bgColor={theme.c0000}
                  onClick={() => handleClickUpdateUser(theme)}
                  isSelected={savedUser.user?.theme?.id === theme.id}
                />
              </div>
            ))
          ) : (
            <IonText>No themes found.</IonText>
          )}
        </>
      )}
    </div>
  );
}

function fetchThemes(token: string): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/collections/themes/records`,
    headers: {
      Authorization: token,
    },
  });
}

function updateTheme(theme: Theme, token: string): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/theme`,
    headers: {
      Authorization: token,
    },
    body: {
      theme: theme.id,
    },
  });
}

import { HttpResponse } from "@capacitor/core";
import { GET, INSTANT_CONFIG_URL } from "../util/ApiClient";

export function getInstantConfig(): Promise<HttpResponse> {
  return GET({
    url: `${INSTANT_CONFIG_URL}/api/_`,
    headers: {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
}

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import {
  IonAlert,
  IonAvatar,
  IonChip,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { Button } from "konsta/react";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import ThemeColorItemsList from "../../components/ThemeColorItem";
import DeleteAccountModal from "../../components/modal/DeleteAccountModal";
import FeedbackModal from "../../components/modal/FeedbackModal";
import type { ModalStateType } from "../../components/modal/Modal";
import Modal from "../../components/modal/Modal";
import { Config } from "../../data/Config";
import { patchUserAvatar } from "../../datasource/user-datasource";
import { SavedUser } from "../../hooks/LoginHook";
import { JIBI_BASE_URL } from "../../util/ApiClient";
import { compressAvatar, getAvatarFile } from "../../util/AvatarExt";
import { UserContext } from "../../util/BetterDatesApp";
import { calculateAge } from "../../util/DateExt";
import { handleError } from "../../util/error";
import PreviewProfile from "./PreviewProfile";

export default function EditProfile() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [modalState, setModalState] = useState<ModalStateType>({
    isVisible: false,
  });
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] =
    useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext?.userState.savedUser;
  const avatarSource = `${JIBI_BASE_URL}/files/_pb_users_auth_/${savedUser.user.id}/${savedUser.user.avatar}`;

  const handleProfileThemeClick = () => {
    setModalState({
      isVisible: true,
      content: <ThemeColorItemsList setModalState={setModalState} />,
      title: "Profile Theme",
    });
  };

  const handleProfilePreviewClick = () => {
    setModalState({
      isVisible: true,
      content: <PreviewProfile />,
      title: "Profile Preview",
    });
  };

  const handleUserFeedbackClick = () => {
    setModalState({
      isVisible: true,
      content: <FeedbackModal setModalState={setModalState} />,
      title: "We need your feedback",
    });
  };

  const handleModalDismiss = () => {
    setModalState({
      isVisible: false,
    });
  };

  const updateAvatar = async (avatarFile: File | Blob): Promise<SavedUser> => {
    const result = await patchUserAvatar(savedUser, avatarFile);
    if (result.status !== 200) {
      return Promise.reject(result);
    }
    const responseBody = await result.json();
    const user = {
      ...savedUser.user,
      avatar: responseBody.avatar,
    };
    return userContext?.loginHook.saveUser({
      ...savedUser,
      user: user,
    });
  };

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col">
          <header className="flex h-14 flex-row items-center px-3">
            <IonRouterLink routerLink="/home" routerDirection="back">
              <IonChip>
                <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                <IonLabel>Back</IonLabel>
              </IonChip>
            </IonRouterLink>
          </header>
          <main className="flex h-full w-full flex-col justify-between overflow-y-scroll">
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col items-center">
                <IonAvatar className="h-24 w-24">
                  <IonImg src={avatarSource} />
                </IonAvatar>
                <div className="mt-2 flex flex-row items-baseline gap-2">
                  <IonText className="text-black-100 font-mono text-2xl font-medium">
                    <div>{savedUser.user.name}</div>
                  </IonText>

                  <IonText className="font-mono text-gray-400">
                    {savedUser.user.birthday
                      ? calculateAge(savedUser.user.birthday)
                      : ""}
                  </IonText>
                </div>
              </div>
              <div className="w-full">
                <IonList inset={true} lines={"none"}>
                  <IonItem
                    button
                    detail={true}
                    lines="none"
                    onClick={() => {
                      Camera.getPhoto({
                        source: CameraSource.Photos,
                        allowEditing: false,
                        saveToGallery: false,
                        quality: 90,
                        resultType: CameraResultType.DataUrl,
                      })
                        .then((result) => {
                          if (!result.dataUrl) {
                            return Promise.reject(result);
                          }
                          getAvatarFile(savedUser.user.id, result.dataUrl)
                            .then((avatarFile) => {
                              if (!avatarFile) {
                                return Promise.reject();
                              }
                              return compressAvatar(avatarFile);
                            })
                            .then((compressedAvatar) => {
                              return updateAvatar(compressedAvatar);
                            })
                            .catch((error) => handleError(error, userContext));
                        })
                        .catch((error) => handleError(error, userContext));
                    }}
                  >
                    <IonLabel>Change profile picture</IonLabel>
                  </IonItem>
                  <IonItem
                    routerLink={"/preferences"}
                    routerDirection={"forward"}
                    button
                    detail={true}
                    lines="none"
                  >
                    <IonLabel>Change your match preferences</IonLabel>
                  </IonItem>
                  <IonItem
                    button
                    detail={false}
                    lines="none"
                    onClick={handleProfileThemeClick}
                  >
                    <IonLabel>Profile Theme</IonLabel>

                    <div
                      style={{
                        background: savedUser.user.theme?.c0000,
                      }}
                      className="h-4 w-4 rounded-full"
                    ></div>
                  </IonItem>
                  <IonItem
                    button
                    detail={true}
                    lines="none"
                    onClick={handleProfilePreviewClick}
                  >
                    <IonLabel>Preview your profile</IonLabel>
                  </IonItem>
                </IonList>
              </div>
            </div>
            <div className="flex w-full flex-col">
              <IonList inset={true} lines={"none"}>
                <IonItem lines={"none"} detail={false}>
                  <div className="flex w-full flex-row items-center gap-2">
                    <IonImg
                      src="/assets/party_popper_emoji.png"
                      className="h-4 w-4"
                    />
                    <IonText className="text-lg font-bold">
                      You are one of our first users.
                    </IonText>
                  </div>
                </IonItem>
                <IonItem detail={false}>
                  <IonLabel class="ion-text-wrap">
                    Help shape the future of dating by sharing your feedback
                    with us.
                  </IonLabel>
                </IonItem>
                <div className="h-11 w-full bg-gray-100">
                  <Button
                    clear
                    className="flex h-full w-full justify-between px-3.5"
                    onClick={handleUserFeedbackClick}
                  >
                    <span>Give us your feedback</span>
                    <span>
                      <ChatBubbleBottomCenterTextIcon className="h-4 w-4" />
                    </span>
                  </Button>
                </div>
              </IonList>
              <IonList inset={true}>
                <IonItem
                  button={true}
                  detail={false}
                  onClick={() => {
                    setIsLogoutConfirmationOpen(true);
                  }}
                >
                  <IonLabel className="ms-2">Logout</IonLabel>
                </IonItem>
              </IonList>
              <IonList inset={true}>
                <IonItem
                  button={true}
                  detail={false}
                  onClick={() => {
                    setIsDeleteConfirmationOpen(true);
                  }}
                >
                  <IonLabel className="ms-2" color={"danger"}>
                    Delete account
                  </IonLabel>
                </IonItem>
              </IonList>
              <IonText className="text-center text-xs text-gray-500">
                {getPrettyAppVersion(userContext.cache.appConfig)}
              </IonText>
              <IonAlert
                isOpen={isLogoutConfirmationOpen}
                id="logoutAlert"
                header="Logout"
                message={`Are you sure you want to logout?`}
                buttons={[
                  {
                    text: "NO",
                    role: "cancel",
                  },
                  {
                    text: "YES",
                    role: "destructive",
                    handler: () => {
                      userContext?.loginHook
                        ?.logoutUser()
                        .then(() => {
                          history.replace("/");
                        })
                        .catch((e) => handleError(e, userContext));
                    },
                  },
                ]}
                onDidDismiss={() => {
                  setIsLogoutConfirmationOpen(false);
                }}
              />
              <IonAlert
                isOpen={isDeleteConfirmationOpen}
                id="deleteAlert"
                header="Delete account"
                message={`Are you sure you want to delete your account?`}
                buttons={[
                  {
                    text: "NO",
                    role: "cancel",
                  },
                  {
                    text: "YES",
                    role: "destructive",
                    handler: () => {
                      setModalState({
                        isVisible: true,
                        content: <DeleteAccountModal />,
                        title: "Delete account",
                        dismiss: "Cancel",
                      });
                    },
                  },
                ]}
                onDidDismiss={() => {
                  setIsDeleteConfirmationOpen(false);
                }}
              />
            </div>
            <Modal
              isOpen={modalState.isVisible}
              onDismiss={handleModalDismiss}
              title={modalState.isVisible ? modalState.title : ""}
              dismiss={modalState.isVisible ? modalState.dismiss : undefined}
            >
              {modalState.isVisible ? modalState.content : ""}
            </Modal>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function getPrettyAppVersion(appConfig: Config): string {
  const nativeVersion =
    appConfig.currentNativeVersion?.version.versionName || "";
  const webVersion = appConfig.currentWebVersion?.version.versionName || "";
  return `${nativeVersion}(${webVersion})`;
}
